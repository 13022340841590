import { NEWBUILDING_PUSH_TO_CALL, NEWBUILDING_TRUST_AND_SAFETY } from 'shared/selectors/abExperiments';

import { HOUSE_CARD_URL_EXPERIMENT } from '../../utils/experiments/houseCardUrl';

export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';

export const EXPERIMENTS: string[] = [
  HOUSE_CARD_URL_EXPERIMENT,
  NEWBUILDING_TRUST_AND_SAFETY,
  NEWBUILDING_PUSH_TO_CALL,
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
];
